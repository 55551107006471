.view-title {
  overflow: hidden;
  margin-bottom: 30px;

  > h1, h2, h3, h4, h5, h6 {
    display: inline;
    position: relative;

    &:after {
      content: "";
      display: block;
      width: 10000%;
      height: 3px;
      background-color: #228BE6;
      position: absolute;
      right: calc(-10000% + -15px);
      top: 50%;
      transform: translateY(3px);
    }
  }
}
