.floating-button {
  border-radius: 50%;
  padding: 20px;
  background-color: #228BE6;
  color: white;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 30px;
  right: 30px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  transition: box-shadow .2s linear;
  cursor: pointer;
  z-index: 10;
  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.35);
  }
}
