html,
body,
#root {
  height: 100%;
  width: 100%;
}

.view {
  padding: 20px;
  min-height: calc(100% - 66px);
  background-color: #f3f3f3;
}

.login {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: top right;
  background-repeat: no-repeat;
}

.page {
  height: 100%;
}

.nav-pills {
  .nav-link {
    &.active {
      background-color: #228BE6 !important;
    }
  }
}

#basic-navbar-nav {
  .navbar-nav {
    border-left: 1px solid rgba(255, 255, 255, .1);
    border-right: 1px solid rgba(255, 255, 255, .1);

    .nav-link {
      border-right: 1px solid rgba(255, 255, 255, .1);
      padding-right: 1rem;
      padding-left: 1rem;
      display: flex;
      align-items: center;

      &:last-child {
        border-right: none;
      }

      svg {
        margin-right: 10px;
      }
    }
  }
}

.navbar-brand {
  display: flex;
  align-items: center;
  svg {
    margin-right: 10px;
    color: #228BE6;
  }
}

.spinner-border {
  margin-right: 5px;
}

.current-operator {
  margin-right: 20px;
}
