.operator-list-item {
  margin-bottom: 20px;
  border-radius: 15px !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.avatar-box {
  width: 80px;
  height: 80px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 50%;
  overflow: hidden;
  margin-left: 1rem;
}

.title-box {
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
  flex: 2;
}

.status-box {
  display: flex;
  align-items: center;
  flex: 2;
}

.status-text {
  margin-left: 10px;
  font-size: .9rem;
}

.actions-box {

}

.button-container {
  margin-right: 20px;
  &:last-child {
    margin-right: 0;
  }
}
