.image-container {
  max-width: 250px;
  margin: 0 auto 20px auto;

  img {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
  }
}
